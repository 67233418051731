import React from "react";
import Navbar from "react-bootstrap/Navbar";

const Navigation = (props) => {
  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/">Vicki </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        {
          props.user != null ? (
            <Navbar.Text>
              Signed in as: {props.user.attributes.email}
            </Navbar.Text>
          ) : null
        }
        {/*<AmplifySignOut />*/}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Navigation