import React, { useEffect } from "react";
import { Auth } from 'aws-amplify'
import { AppConfig } from "../config";

const Login = () => {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => console.log("Login - logged in as:", user))
      .catch(e => {
          console.error(e);
          window.location.assign(
            'https://' + AppConfig.cognitoDomain + '/login?' +
            'response_type=code' +
            '&client_id=' + AppConfig.clientId +
            '&redirect_uri=' + AppConfig.homeUrl
          )
      });
  }, []);

  return (
    <div/>
  )
}

export default Login