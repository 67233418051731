import React, { useEffect, useState } from 'react'
import Amplify, {API, Auth} from 'aws-amplify'
import { withOAuth } from 'aws-amplify-react'
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from "./config";
import Navigation from "./component/Navigation";
import Login from "./component/Login"
import CreateNotification from "./component/CreateNotification"
import SentNotifications from "./component/SentNotifications"
import {notificationByDate} from "./graphql/queries";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";

Amplify.configure(config);

const App = () => {
  const [currentUser, setCurrentUser] = useState(null)
  const [notifications, setNotifications] = useState([])
  const [nextToken, setNextToken] = useState(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setCurrentUser(user);
        fetchNotifications().then();
      })
      .catch(() => console.log("User not logged in"));
    //eslint-disable-next-line
  }, []);

  async function fetchNotifications() {
    try {
      const notificationData = (await API.graphql({
        query: notificationByDate,
        variables: {
          limit: 10,
          type: '1',
          sortDirection: "DESC"
        }
      })).data.notificationByDate

      setNotifications(notificationData.items);
      updateNextToken(notificationData.nextToken);
    } catch (err) {
      console.log('error fetching notifications', err);
      updateNextToken(null);
    }
  }

  function updateNextToken(nextToken) {
    setNextToken(nextToken);
    const moreTokenButton = document.getElementById('loadMoreTokenButton');

    if (nextToken == null) {
      moreTokenButton.setAttribute('disabled', '');
      moreTokenButton.setAttribute('class', 'btn btn-secondary');
    } else {
      moreTokenButton.removeAttribute('disabled');
      moreTokenButton.setAttribute('class', 'btn btn-primary');
    }
  }

  function newNotificationCreated() {
    fetchNotifications().then();
  }

  function moreNotifications() {
    API.graphql({
      query: notificationByDate,
      variables: {
        limit: 10,
        type: '1',
        sortDirection: "DESC",
        nextToken: nextToken
      }
    }).then(result => {
      updateNextToken(result.data.notificationByDate.nextToken);
      setNotifications([...notifications, ...result.data.notificationByDate.items]);
    }).catch(e => {
      console.log('error fetching notifications', e);
    });
  }

  return (
    <Container fluid>
      <Navigation user={currentUser} />
      <br/>
      <Login />
      { currentUser != null &&
        <Container fluid="md">
          <CreateNotification onNewNotification={newNotificationCreated}
                              user={currentUser} />
        </Container>
      }
      <Jumbotron>
        { currentUser != null &&
            <SentNotifications notifications={notifications}
                               user={currentUser} /> }
        <Button id='loadMoreTokenButton' onClick={moreNotifications} >Load more...</Button>
      </Jumbotron>
    </Container>
  )
}

export default withOAuth(App)
