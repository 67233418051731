/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTopics = /* GraphQL */ `
  query GetTopics {
    getTopics {
      displayName
      arn
    }
  }
`;
export const validateArticle = /* GraphQL */ `
  query ValidateArticle($articleId: String) {
    validateArticle(articleId: $articleId) {
      statusCode
      error
      article {
        id
        title
      }
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      message
      article
      ttl
      topic {
        displayName
        arn
      }
      sentBy
      type
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message
        article
        ttl
        topic {
          displayName
          arn
        }
        sentBy
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationByDate = /* GraphQL */ `
  query NotificationByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        article
        ttl
        topic {
          displayName
          arn
        }
        sentBy
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
