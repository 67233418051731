import React, {useEffect, useState} from "react";
import {API, graphqlOperation} from "aws-amplify";
import Jumbotron from "react-bootstrap/Jumbotron";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {getTopics, validateArticle} from "../graphql/queries";
import {createNotification} from "../graphql/mutations";

const CreateNotification = (props) => {
  const initialState = { message: '', article: '', ttl: 0, topic: '' }
  const [formState, setFormState] = useState(initialState)
  const [topics, setTopics] = useState([])
  let validArticleSet = false;

  const validUrlPatterns = [
    /https:\/\/.*\.stuff\.co\.nz.*/,
    /https:\/\/stuff\.co\.nz.*/
  ]

  useEffect(() => {
    fetchTopics()
  }, []);

  async function fetchTopics() {
    try {
      const topics = await API.graphql(graphqlOperation(getTopics))

      if (topics.data.getTopics == null) {
        console.log("Error fetching topics");
        return;
      }

      setTopics(topics.data.getTopics);
    } catch (err) { console.log('error fetching topics') }
  }

  function setMessage(value) {
    formState.message = value;
    isAllDataAvailable();
  }

  function setTtl(value) {
    formState.ttl = value;
  }

  function setTopic(value) {
    formState.topic = value;
    isAllDataAvailable();
  }

  function setArticle(value) {
    formState.article = value;
    isAllDataAvailable();
  }

  function isAllDataAvailable() {
    let allDataAvailable = (formState.message && formState.topic && formState.article && validArticleSet);
    const sendButton = document.getElementById("sendButton");

    if (allDataAvailable) {
      sendButton.removeAttribute("disabled");
      sendButton.addEventListener('click', pushNotification);
    } else {
      sendButton.setAttribute("disabled", "disabled");
    }

    return allDataAvailable;
  }

  async function pushNotification() {
    if (!isAllDataAvailable()) {
      return;
    }

    const notification = { ...formState }
    notification.sentBy = props.user.attributes.email
    notification.topic = topics.find(topic => topic.arn === notification.topic);
    notification.type = "1";

    try {
      await API.graphql(graphqlOperation(createNotification, {input: notification}));
      props.onNewNotification(notification);
      resetForm();
    } catch (err) {
      console.log('error publishing notification:', err.errors[0].message)
    }
  }

  function resetForm() {
    document.getElementById("formState.message").value = "";
    document.getElementById("formState.article").value = "";
    document.getElementById("formState.ttl").value = 0;
    document.getElementById("formState.topic").selectedIndex = 0;
    validArticleSet = false;
    setFormState(initialState);
    hideFeedback();
    isAllDataAvailable();
  }

  function hideFeedback() {
    setFeedback('', 'hidden');
  }

  function setArticleInputOnUserInput(value) {
    validArticleSet = false;
    formState.article = value;
    hideFeedback();
  }

  function setFeedback(message, className) {
    const feedback = document.getElementById("articleIdFeedback");
    feedback.innerHTML = message;
    feedback.className = className;
  }

  async function validateOnEnter(event) {
    if (event.type === 'keypress' && event.key === 'Enter') {
      lookupArticle(event.target.value);
    }
  }

  async function lookupArticle(value) {
    validArticleSet = false;

    if (/^\d+$/.test(value)) {
      await validateArticleId(value);
    } else if (!validStuffUrl(value)) {
      articleValidationError("Not a valid Stuff URL");
    } else {
      checkStuffUrlForArticleId(value);
    }
  }

  function checkStuffUrlForArticleId(value) {
    if (value.includes('interactives.stuff.co.nz')) {
      validArticleFound();
      return;
    }

    value.split('/').forEach(urlPart => {
      if (/^\d+$/.test(urlPart)) {
        validateArticleId(urlPart)
          .then(isValid => {
            if (isValid) {
              setArticle(urlPart);
            }
          });
      }
    });
  }

  function validStuffUrl(url) {
    return validUrlPatterns.some(pattern => pattern.test(url));
  }

  async function validateArticleId(id) {
    if (!id) {return false;}

    const response = (await API.graphql(graphqlOperation(
      validateArticle, {articleId: id})
    )).data.validateArticle;

    console.log(response);

    if (response.statusCode === "200") {
      articleValidationSuccess(response.article.title);
      return true;
    } else {
      articleValidationError("Article not found");
    }

    return false;
  }

  function validArticleFound() {
    validArticleSet = true;
    isAllDataAvailable();
  }

  function articleValidationSuccess(message) {
    validArticleFound();
    articleValidationFeedback(message, 'success');
  }

  function articleValidationError(message) {
    validArticleSet = false;
    setArticle(null);
    articleValidationFeedback(message, 'danger');
  }

  function articleValidationFeedback(message, type) {
    setFeedback(message, 'fade alert show alert-' + type);
  }

  if (props.user == null) return null;

  return (
    <Jumbotron>
      <Form>
        <h2>New Notification</h2>
        <Form.Group controlId="formState.message">
          <Form.Label>Message:</Form.Label>
          <Form.Control as="textarea" rows={3} placeholder="At least 10 characters"
                        onChange={event => setMessage(event.target.value)}
                        onBlur={event => setMessage(event.target.value)}/>
          <Form.Text className="text-muted">
            This message will appear on the users device.
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formState.article">
          <Form.Label>Article:</Form.Label>
          <Form.Control type="text" placeholder="Article ID or URL for the article"
                        onChange={event => setArticleInputOnUserInput(event.target.value)}
                        onKeyPress={event => validateOnEnter(event)}
                        onBlur={event => lookupArticle(event.target.value)}/>
          <div id="articleIdFeedback" role="alert" className="hidden" />
          <Form.Text className="text-muted">
            The article will be shown when the app opens
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formState.ttl">
          <Form.Label>Time to live:</Form.Label>
          <Form.Control type="number" min='0'
                        placeholder="Expiry time in minutes (1440 minutes in a day)"
                        onChange={event => setTtl(event.target.value)}/>
          <Form.Text className="text-muted">
            Cancel delivery, if not delivered after this many minutes (optional)
          </Form.Text>
        </Form.Group>
        <Form.Group controlId="formState.topic">
          <Form.Label>Topic:</Form.Label>
          <Form.Control as="select"
                        onChange={event => setTopic(event.target.value)}>
            <option key="-1" value="">Choose...</option>
            {
              topics.map((topic, index) => (
                <option key={index} value={topic.arn}>{topic.displayName}</option>
              ))
            }
          </Form.Control>
        </Form.Group>
        <Button variant="success" id="sendButton" disabled={true}>Send notification</Button> {' '}
        <Button variant="danger" type="reset">Reset</Button> {' '}
      </Form>
    </Jumbotron>
  )
}

export default CreateNotification