import moment from "moment";
import Table from "react-bootstrap/Table";
import React from "react";

const SentNotifications = (props) => {
  if (props.user == null) return null;

  return (
    <div>
      <h2>Sent Notifications</h2>
      <Table>
        <thead>
        <tr>
          <th>Message</th>
          <th>Article</th>
          <th>Sent by</th>
          <th>Date</th>
          <th>Topic</th>
        </tr>
        </thead>
        <tbody>
        {
          props.notifications.map((notification, index) => (
            <tr key={notification.id ? notification.id : index}>
              <td>{notification.message}</td>
              <td>{notification.article}</td>
              <td>{notification.sentBy}</td>
              <td>{moment(notification.createdAt).format('MMM D YYYY, LT')}</td>
              <td>{notification.topic.displayName}</td>
            </tr>
          ))
        }
        </tbody>
      </Table>
    </div>
  )
}

export default SentNotifications
