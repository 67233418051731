const AppConfig = {
    region: "ap-southeast-2",
    homeUrl: "https://test.vicki.stuff.co.nz/",
    cognitoDomain: "test-auth.vicki.stuff.co.nz",
    clientId: "q1cu210onetrkhbj5f154onlo"
}

const AmplifyConfig = {
    Auth: {
        region: AppConfig.region,
        userPoolId: "ap-southeast-2_UdXp5t7fv",
        userPoolWebClientId: AppConfig.clientId,
        oauth: {
            domain: AppConfig.cognitoDomain,
            scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
            redirectSignIn: AppConfig.homeUrl,
            redirectSignOut: AppConfig.homeUrl,
            responseType: 'code'
        }
    },
    aws_appsync_region: AppConfig.region,
    aws_appsync_graphqlEndpoint: "https://3wutc3n4yvbppl5ejukq4uzvtq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
};

export default AmplifyConfig;
export {AppConfig}